import Request from "../../http/http";
import {Config} from "../../config";

const sendInvites = (type="free",data) =>async  (dispatch)=>{
  try {


    return await Request.post(Config.Inviter.send, {
      type:data.type,
      data: {
        event: data.event,
        data: data.data
      }
    });
  }catch (e) {
    console.log(e.message)
  }
}
const sendPaidInvite = (data) =>{
  return  Request.post(Config.Inviter.sendPaidInvite, data);
}
const getInvites = () =>async  (dispatch)=>{
  try {
    const response = await Request.get(Config.Inviter.get);
    return response.data.status? response.data.data:[];
  }catch (e) {
    console.log(e.message)
  }
}
const sendOrder = ({id}) =>{
  return Request.get(Config.Inviter.sendOrder.replace("{inviteId}",id));
}
const deleteOrder=({id})=>{
  return Request.get(Config.Inviter.deleteOrder.replace("{transId}",id));
}
export default  {
  sendInvites,
  getInvites,
  sendPaidInvite,
  sendOrder,
  deleteOrder
}

