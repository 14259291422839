import React, {useEffect, useMemo, useState} from 'react';
import {Actions,Request} from "../../core";
import {useDispatch,useSelector} from "react-redux";
import _ from 'lodash';
import {CHANGE_LANGUAGE, GET_LABELS} from "../../core/core/store/actionTypes";
import {ShareBtn, ShareComponent} from "../index";
import Moment from "moment";

export const Labels = ({id,handleTabs,checkActive,onSelectItem,page,onSelectLabel,type}) =>{

	const dispatch = useDispatch();
	const data = useSelector(state=>state.Store.Label);
	const lang = useSelector(state=>state.Lang);
  const [loader,setLoader] = useState(false);
  const [label, setLabel] = useState(null)
  const [gallery, setGallery] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [filter,setFilter]= useState("") ;
  const [title,setTitle] = useState("Record Label");
  useEffect(()=>{
    if(page==='label' && (id ==='bassiani' || id ==='horoom')){
      setTitle(id.toString().charAt(0).toUpperCase().concat(id.slice(1)).concat(" Records"))
      if(_.size(data[id])===0){
        dispatch(Actions.Label.getLabels(id))
      }
    }else if(page==='label' &&  parseInt(id)>0){
      setTitle(type.toString().charAt(0).toUpperCase().concat(type.slice(1)).concat(" Records"))
      loadLabel().finally();
    }
    return ()=>{
      setLabel(null);
      setFilter("");
      setTitle("Record Label");
    }
  },[id])


  const getData = useMemo(() =>{

        if(filter){
          return _.filter(data?.data,v=>{
            return v?.type===filter
          })
        }
        return data?.data ||[];
  }, [id,data,filter]);
  const loadLabel=async () => {
    const response = await dispatch(Actions.Label.getLabel(id))
    try {
      let media = JSON.parse(response.data.data[0].gallery) ;
      if(media && media.length  > 0){
        setGallery(media);
      }else{
        setGallery([...gallery,{ path: response['data']['data'][0]["main_image_path"] }])
      }
    }catch (e) {
      setGallery([...gallery,{ path: response['data']['data'][0]["main_image_path"] }])
    }
    setLabel(response.status? response.data.data[0]:null)
  }
  const renderPopup=()=> {

    return (
      <div className="popup">
        <section className="left-side-sec">
          <div className="left-side-title">
            {label.title}
          </div>
          <div className="popup-lang" onClick={()=>dispatch({
            type:CHANGE_LANGUAGE,
            payload: lang==='ka'? 'en':'ka'
          })}>{lang==='ka'? 'EN':'KA'}</div>
          <div className="popup-text">
							  <span className="text-title">
									{label.sub_title}
								</span>
            <span className="text-subtitle" dangerouslySetInnerHTML={{__html: label.short_text}}/>
            <span dangerouslySetInnerHTML={{__html: lang==='ka'?label.text_ge: label.text_en}}/>
          </div>
          <div className="popup-footer" >
            <ShareBtn
              host={window.location.origin}
              description={""}
              image={window.location.origin+label.share_image_path}
              title={label.title}
              url={window.location.pathname}
            />
          </div>
        </section>
        <section className="right-side-sec">
          <div className="right-side-title">
            <p>
              <span>RELEASE DATE: {Moment(label.release_date).format("DD MMM, YYYY")}</span>
              <span style={{fontFamily:'meomari',fontWeight:'900'}}>{label.stock ==="yes"?"in stock":"out of stock"}</span>
            </p>
            <p id="store-order">
            {label.stock ==="yes"?(
              <a href={label.order_link} target={"_blank"}><span>ORDER</span></a>
            ):null}

            </p>

          </div>
          <div className="right-side-img" style={{backgroundImage:`url(${gallery[galleryIndex]['path']})`}}>
            {
              (gallery.length>1)?
                <div className="gallery-arr arrow_prev" onClick={()=>setGalleryIndex(galleryIndex<=0? (gallery.length-1):(galleryIndex-1))}/>:null
            }
            {
              (gallery.length>1)?
                <div className="gallery-arr arrow_next" onClick={()=>setGalleryIndex((galleryIndex>=gallery.length-1)? 0:(galleryIndex+1))}/>:null
            }
          </div>
        </section>
      </div>
    )
  }



  const  renderLabels=()=> {
    return (
      loader?"იტვირთება...":(
        <ul className="card-ul">
          <li className="title-li">
            <span className={filter === 'ep' ? 'active' : ''}
                  onClick={() => setFilter(filter === 'ep' ? "" : 'ep')}>EP</span>
            <span className={filter === 'lp' ? 'active' : ''} onClick={() => {
              setFilter(filter === 'lp' ? "" : 'lp')
            }}>LP</span>
            <span className={filter === 'dgtl' ? 'active' : ''} onClick={() => {
              setFilter(filter === 'dgtl' ? "" : 'dgtl')
            }}>DGTL</span>
          </li>
          {
            _.map(getData, (v, k) => {
              return (
                <li className="card-li" key={k} onClick={() =>onSelectLabel(id,v.id)}>
                  <div className="card-img"  style={{backgroundImage: `url(${v.main_image_path})`}}/>
                  <div className="card-title">
                    <span>{v.title}</span>
                    <span>{v.sub_title} </span>
                    <span dangerouslySetInnerHTML={{__html:v.short_text}}/>
                  </div>
                </li>
                )
            })
          }
        </ul>
      )
    )
  }
  return (
		<>
			<li id="label"  className={`${checkActive('label')}`} >
				<div className="item-head" onClick={()=>handleTabs("label")}><span>{title}</span></div>
				{
            <div className="item-body">
              {
                ((id ==='horoom' || id ==='bassiani') )?
                  (
                    <>
                      {
                        renderLabels()
                      }
                    </>
                  ) :
                  ( label? renderPopup():
                    <ul className="records">
                      <li id="bassiani-rec" onClick={()=>{
                        onSelectItem("bassiani")
                      }}>Bassiani Records</li>
                      <li id="horoom-rec" onClick={()=>{
                        onSelectItem("horoom")
                      }}>Horoom Records</li>
                    </ul>
                  )
              }
            </div>
				}
			</li>
		</>
	)
}
