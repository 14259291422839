import React,{useEffect,useState} from 'react';
import {Actions,Request} from "../../core";
import {useDispatch,useSelector} from "react-redux";
import _ from 'lodash';
import {isNotEmpty} from "../../core/core/utils";
import {CHANGE_LANGUAGE} from "../../core/core/store/actionTypes";
import {ShareBtn} from "../index";
export const Residents = ({id,page,handleTabs,checkActive,onSelectArtist}) =>{

	const dispatch = useDispatch();
  const lang = useSelector(state=>state.Lang);
  const [socialMedia,setSocialMedia]=useState([]);
  const [showSocialMedia,setShowSocialMedia]=useState('false');

  const [loader,setLoader] = useState(true);
	const data = useSelector(state=>state.Store.Artist);
	const [selected, setSelected] = useState(-1);
	const [resident,setResident] = useState(null)
  const [gallery, setGallery] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const loadResident=async (id) => {
    const response = await dispatch(Actions.Artist.getArtist(id));


    try {
      let media = JSON.parse(response.data.data[0].gallery) ;
      let social = JSON.parse(response.data.data[0].socialMedia) ;
      console.log(media)
      if(social && social.length  > 0){
        setSocialMedia(social);
      }

      if(media && media.length  > 0){
        setGallery(media);
      }else{
        setGallery([...gallery,{ path: response['data']['data'][0]["main_image_path"] }])
      }
    }catch (e) {
      setGallery([...gallery,{ path: response['data']['data'][0]["main_image_path"] }])
    }
    setResident(response.status? response.data.data[0]:null)
  }
	useEffect(()=>{
		Request.subscribeLoader("resident-loader",res=>{
			setLoader(res)
		});
		return ()=>{
			Request.unsubscribeLoader("resident-loader",()=>console.log("unsubscribe loader"))
		}
	},[dispatch])

  useEffect(()=>{
    if(page==="artists"){
      dispatch(Actions.Artist.getArtists());
    }
  },[page])


  useEffect(()=>{
    console.log(id,page)
    if(isNotEmpty(id) && page==='artists'){
      loadResident(id).finally();
    }else{
      setResident(null)
    }
    setSelected(id)
  },[id])
	return (
		<>
			<li id="residents"  className={`${checkActive('artists')}`} >
				<div className="item-head" onClick={()=>handleTabs("artists")}><span>Artists</span></div>
				{
         <div className="item-body">
           {
             resident? 	<div className="popup">
               <div className="popup-title">
                 {resident.title}
                 <span className="back"/>
               </div>
               <section className="left-side-sec">
                 <div className="popup-lang" onClick={()=>dispatch({
                   type:CHANGE_LANGUAGE,
                   payload: lang==='ka'? 'en':'ka'
                 })}>{lang==='ka'? 'EN':'KA'}</div>
                 <div className="popup-text" >
                   <div dangerouslySetInnerHTML={{__html: lang==='ka'?resident.text_ge: resident.text_en}}/>
                 </div>
                 {/*<div className="popup-play">
                   <iframe title={"test2"} id="sd-frame" width='100%' height='500' scrolling='no'
                           frameBorder='no' allow='autoplay'
                           src='https://w.soundcloud.com/player/?url=https://api.soundcloud.com/users/807541&auto_play=false&buying=false&liking=false&download=true&sharing=true&show_artwork=false&show_comments=false&show_playcount=false&show_user=false&hide_related=true&visual=false&start_track=0&callback=true'></iframe>
                 </div>*/}
                 <div className="popup-footer">
                   <ShareBtn
                     host={window.location.origin}
                     description={""}
                     image={window.location.origin+resident.share_image_path}
                     title={resident.title}
                     url={window.location.pathname}
                     />
                 </div>

                 {
                   (socialMedia.length>0)?
                     <div className={`popup-drop ${showSocialMedia?'active':""}`} >
                       <div className="popup-drop-arrow" onClick={()=>setShowSocialMedia(!showSocialMedia)}/>
                       <div className="popup-drop-box" style={{height: `${ showSocialMedia? socialMedia.length * 30 :0}px`}}>
                         {
                           _.map(socialMedia,(v,key)=>{
                             return   <p key={key}><a href={v.link} target="_blank">{v.source}</a></p>
                           })
                         }

                       </div>
                     </div>:null

                 }

               </section>
               <section className="right-side-sec" style={{backgroundImage: `url(${gallery[galleryIndex].path})`}}>

                 {
                   (gallery.length>1)?
                     <div className="gallery-arr arrow_prev" onClick={()=>setGalleryIndex(galleryIndex<=0? (gallery.length-1):(galleryIndex-1))}/>:null
                 }
                 {
                   (gallery.length>1)?
                     <div className="gallery-arr arrow_next" onClick={()=>setGalleryIndex((galleryIndex>=gallery.length-1)? 0:(galleryIndex+1))}/>:null
                 }

               </section>
             </div>:
               <ul className="collapse-ul">
                 {
                   _.map(data, (value, index)=>{
                     return <li className={`collapse-li ${selected === index ? "active":""}`} key={index} onClick={()=>setSelected(selected === index? -1: index)}>
                       <div className="li-body img-filter" style={{backgroundImage: `url(${value.main_image_path})`}} onClick={(e)=>{
                         e.preventDefault();
                         onSelectArtist("artists",value.id)
                       }}/>
                       <div className="li-head">{value.title}</div>
                     </li>
                   })
                 }

               </ul>
           }
					</div>
				}
			</li>
		</>
	)

}
