import Request from "../../http/http";
import {Config} from "../../config";
import {GET_PRODUCTS} from "../actionTypes";

const getProducts = () =>async (dispatch)=>{
	const response = await Request.get(Config.Products.get);
	dispatch({
		type: GET_PRODUCTS,
		payload: response.status? response.data.data.posts:[]
	})
}
const getProduct = (id) =>async ()=>{
  return await Request.get(Config.Products.getProduct+id);

}
export default {
  getProduct,
  getProducts
}
