import React, {useEffect, useState} from "react";
import {Actions} from "../../core";
import  _ from 'lodash';
import CloseIcon from "./icons8-close.svg"
export const Jobs = ()=>{
  const [jobs,setJobs]=useState([])
  const [select,setSelect]=useState(null)
  useEffect(() => {
    Actions.User.jobs().then(response=>{
      if(response?.data?.status===1){
          setJobs(response?.data?.data)
      }else{
        setJobs(null)
      }
    })
  }, []);


  const renderJobs=()=>{

     if(jobs===null){
        return <span style={{fontSize:'1.125rem',lineHeight:`1.8rem`,fontFamily:`'FiraGO', sans-serif`}}>Thank you for your interest! We currently do not have any open positions. Please check back later for any updates.</span>
     }
     if(select){
        return  (
          <div>
            <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
              <span  style={{fontSize:'2rem',fontFamily:`meomari, FiraGO, sans-serif`,fontWeight:800}}  dangerouslySetInnerHTML={{__html:select?.title}}/>
              <div style={{display:'flex',justifyContent:'flex-end',fontSize:30,fontWeight:300,cursor:'pointer'}} onClick={()=>setSelect(null)}>
                <img src={CloseIcon} alt="" width={30}/>
              </div>
            </div>
            <div style={{marginTop:'1rem'}}>
              <span style={{fontSize:'1.125rem',lineHeight:`1.8rem`,fontFamily:`'FiraGO', sans-serif`}} dangerouslySetInnerHTML={{__html:select?.description}} />
            </div>
          </div>
        )
     }

     if(jobs.length>0){
        return  _.map(jobs, job=>{
            return (
              <div key={job.id} style={{borderBottom:'1px solid #c1bebe',fontSize:'2rem',cursor:'pointer',display:'flex',justifyContent:'space-between'}} onClick={()=>setSelect(job)}>
                <div style={{fontFamily:`meomari, FiraGO, sans-serif`,fontWeight:800}}>
                  {job.title}
                </div>
                <div style={{fontSize:'2rem'}}>
                  +
                </div>
              </div>
            )
        })
     }

     return "loading ..."
  }

  return (
    <div className="lost-form">
      <div style={{
        padding:'1.5rem 2.1rem',
        height:'100%'
      }}>
        {
          renderJobs()
        }
      </div>

    </div>
  )
}
