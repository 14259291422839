export const Config ={
  CHECK_TRANSACTION:"/api/?app=WebUser&resource=checkTransaction&uuid={uuid}",
  SEARCH:"/api/?app=WebGuest&resource=search",
   User:{
      Auth: "/api/?app=user/Auth",
     Ping: "/api/?app=Ping",
     Logout:"/api/?app=user/Logout",
     Register:"/api/?app=user/Register",
     Reset:"/service/guest/resetPassword/bassiani/?email={email}",
     CheckToken:"/api/?app=WebGuest&resource=resetPassword&token={token}",
     UpdatePassword:"/api/?app=WebGuest&resource=changePassword&password={password}",
     Tickets: "/api/?app=WebUser&resource=tickets",
     LostAndFound:"/guest/lostAndFound",
     Jobs:"/api/?app=WebGuest&resource=jobs",
     Rules:"/api/?app=WebGuest&resource=rules",
   },
  Media:{
     Upload: "/api/?app=media/Media&resource=upload",
    GetFiles:"/api/?app=media/Media&resource=get"
  },
  Blog:{
     get: "/api/?app=WebBlog&resource=list&page=1",
      getBlog: "/api/?app=WebBlog&resource=row&id=",
     Delete:"/api/?app=blog/Manifest&resource=delete",
     Add: "/api/?app=blog/Manifest&resource=add",
    Publish:"/api/?app=blog/Manifest&resource=status",
    Update: "/api/?app=blog/Manifest&resource=edit"
  },
  PodCast:{
    get: "/api/?app=WebPodcast&resource=list&page=1",
    getId: "/api/?app=WebPodcast&resource=row&id=",
  },
  Label:{
    get: "/api/?app=WebLabel&resource=list&page=1",
    getId: '/api/?app=WebLabel&resource=row&id='

  },
  Products:{
    get: "/api/?app=WebProduct&resource=list&page=1",
    getProduct: "/api/?app=WebProduct&resource=row&id=",
  },
  Artists:{
    get: "/api/?app=WebArtist&resource=list&page=1",
    getArtist: "/api/?app=WebArtist&resource=row&id=",
    Delete:"/api/?app=artist/Artist&resource=delete",
    Add: "/api/?app=artist/Artist&resource=add",
    Publish:"/api/?app=artist/Artist&resource=status",
    Update: "/api/?app=artist/Artist&resource=edit"
  },
  Events:{
      get: "/api/?app=WebNight&resource=list&page=1",
      event: '/api/?app=WebNight&resource=row&id=',

  },
  Inviter:{
    //send:"/service/guest/invitation/guest/send",
    send:"/api/?app=WebUser&resource=sendInvites",
    sendPaidInvite:"/api/?app=WebUser&resource=sendPaidInvites",
    get:"/api/?app=WebUser&resource=getInvites",
    sendOrder: "/guest/send/paymentOrder/{inviteId}",
    deleteOrder: "/api/?app=WebService&resource=deleteOrder&id={transId}",
    getGuests:"/api/?app=WebUser&resource=getGuests&name={name}"
  }
  ,
  Ticket:{
    buy:"/api/?app=WebService&resource=createOrder",
    redirect:"/payment"
  },
  Cart:{
    Checkout:"/api/?app=WebService&resource=checkout"
  }
}
