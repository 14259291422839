import { GET_SEARCH} from "../actionTypes";

export const  Search = (state=[], action)=>{
	switch (action.type){
		case GET_SEARCH:
			return action.payload;
		default:
			return  state;
	}
}
