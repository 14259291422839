import Request from "../../http/http";
import {Config} from "../../config";
import { GET_BLOGS} from "../actionTypes";

const getBlogs = () =>async (dispatch)=>{
	const response = await Request.loader("blog-loader").get(Config.Blog.get);

	dispatch({
		type: GET_BLOGS,
		payload: response.status? response.data.data.posts:[]
	})

}
const getBlog = (id) =>async ()=>{
	return await Request.get(Config.Blog.getBlog+id);

}

export default {
  getBlogs,
  getBlog
}
