import { combineReducers } from 'redux';
import {CHANGE_THEME,CHANGE_LANGUAGE} from "../actionTypes";
import {Sidebar} from  './Sidebar'
import {User} from "./User";
import {Slot} from "./Slot";
import {Template} from "./Template";
import {PopupWindow} from "./PopupWindow";
import {PodCast} from "./PodCast";
import {Artist} from "./Artist";
import {Blog} from "./Blog";
import {Label} from "./Label";
import {Product} from "./Product";
import {Event, SelectedEvent} from "./Event";
import {Search} from "./Search";

const Store = combineReducers({
    Sidebar,
    User,
    Slot,
    Template,
    PopupWindow,
    PodCast,
    Artist,
    Event,
    SelectedEvent,
    Blog,
    Label,
  Product,
  Search
})


const Theme = (state="dark",action )=>{
    switch (action.type){
        case CHANGE_THEME:
            return action.payload;
        default:
            return  state;
    }
}
const Lang = (state="ka",action )=>{
  switch (action.type){
    case CHANGE_LANGUAGE:
      return action.payload;
    default:
      return  state;
  }
}

const root = combineReducers({
    Store,
    Theme,
    Lang
});

export default root;
