import {GET_EVENT, GET_EVENTS} from "../actionTypes";

export const  Event = (state=[], action)=>{
	switch (action.type){
		case GET_EVENTS:
			return action.payload;
		default:
			return  state;
	}
}

export const  SelectedEvent = (state={}, action)=>{
	switch (action.type){
		case GET_EVENT:
			return action.payload;
		default:
			return  state;
	}
}

