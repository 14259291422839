import {AUTH, PING, SET_USER_DATA, LOGOUT, MYTICKETS, GET_SEARCH} from "../actionTypes";
import Request from "../../http/http";
import {Config} from "../../config";
const auth = ({username,password,token}) =>async (dispatch)=>{
    const response = await Request.post(Config.User.Auth+"&username="+username+"&password="+password+"&token="+token,{})
    if(response.status){
        dispatch({
          type: AUTH,
          payload: true
        })
       dispatch({
         type: "POPUP_WINDOW",
         payload: false

       });
        dispatch(ping())
      return true;
    }else{
      return false;
    }

}
const ping = () =>async (dispatch)=>{
  const response = await Request.post(Config.User.Ping,{})

  if(response.status){
    dispatch({
      type:SET_USER_DATA,
      payload: response.data.data.data
    })
  }else {
    dispatch({
      type: PING,
      payload: response.status
    })
  }


}
const Register=(token, userdata)=>async (dispatch)=>{
    userdata['token']=token;
    const response = await Request.post(Config.User.Register,userdata);
   return response;
}
const Logout =  () =>async (dispatch)=>{
  const response = await Request.get(Config.User.Logout)
  if(response.status){
    dispatch({
      type:LOGOUT,
      payload: null
    })
  }else{
    alert("სისტემიდან გასვლისას დაფიქსირდა შეცდომა")
  }

}
const ResetPassword =  (email) => {
  return Request.get(Config.User.Reset.replace("{email}",email))
}
const CheckToken  = (token)=>{
  return Request.get(Config.User.CheckToken.replace("{token}",token))
}
const UpdatePassword  = (password)=>{
  return Request.get(Config.User.UpdatePassword.replace("{password}",password))
}
const GetMyTickets = ()=>async (dispatch)=>{
   const response = await  Request.get(Config.User.Tickets);
    dispatch({
      type: MYTICKETS,
      payload: response.status? response.data.data:[]
    });
}

const jobs = ()=>{
  return Request.get(Config.User.Jobs)
}
const rules = ()=>{
  return Request.get(Config.User.Rules)
}

const LostAndFound =  ({fullName,email,phone,dateOfLost,description}) => {
  let formData = new FormData();

  formData.append("fullName",fullName)
  formData.append("email",email)
  formData.append("phone",phone)
  formData.append("dateOfLost",dateOfLost)
  formData.append("description",description)

  return Request.post(Config.User.LostAndFound,formData)

}
const search = ()=>async (dispatch)=>{

     Request.get(Config.SEARCH).then(response=>{
        if(response.status){
          dispatch({
            type:GET_SEARCH,
            payload:response.data
          })
        }
     })
}
const checkTransaction = (uuid)=>{
  return Request.get(Config.CHECK_TRANSACTION.replace("{uuid}",uuid))
}
export default {
  auth,
  ping,
  Logout,
  Register,
  ResetPassword,
  CheckToken,
  UpdatePassword,
  GetMyTickets,
  LostAndFound,
  search,
  checkTransaction,
  jobs,
  rules
}
