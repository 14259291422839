import { GET_BLOGS} from "../actionTypes";

export const  Blog = (state=[], action)=>{
	switch (action.type){
		case GET_BLOGS:
			return action.payload;
		default:
			return  state;
	}
}
