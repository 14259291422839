import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Actions} from "../../core";
import {useDispatch,useSelector} from "react-redux";
import _ from 'lodash';
import {BlogPopup} from "./BlogPopup";
import {isNotEmpty} from "../../core/core/utils";
import {TimeOut} from "../../core/core/utils/TimeOut";

export const Blogs = ({id,handleTabs,checkActive,onSelectItem,page}) =>{
	const dispatch = useDispatch();
	const data = useSelector(state=>state.Store.Blog);
	const [selected, setSelected] = useState(-1);
	const [blog,setBlog] = useState(null);
  let [index,setIndex]=useState(10)
  const ref= useRef(null)


  const loadBlog=async (id) => {
    const response = await dispatch(Actions.Blog.getBlog(id));
    setBlog(response.status? response.data.data[0]:null)
  }
  useEffect(()=>{
    if(isNotEmpty(id) && page==='blog' &&  id>0){
      loadBlog(id).finally();
    }
    setSelected(id)
  },[id])

	useEffect(()=>{
    if(page==="blog" && _.size(data)===0){
      dispatch(Actions.Blog.getBlogs());
    }
    if(ref.current){
      ref.current.addEventListener("scroll", function(e){
        TimeOut.clear();
        TimeOut.set(()=>{
          setIndex(index+=5)
        },100)
      })
    }
    return ()=>{
        ref.current.removeEventListener("scroll", ()=>{
          setIndex(20)
        })

    }

	},[page])

  const getData = useMemo(() => {
    return data.filter((v,k)=>k<=index)

  }, [data,index]);

  return (
	  <>
          <li id="blog" className={`${checkActive('blog')}`}>
            <div className="item-head" onClick={() => handleTabs("blog")}><span>Narratives</span></div>
            {
                (
                  <div className="item-body" ref={ref}>
                    {
                      (selected !== undefined && selected>0 && blog)?
                        <BlogPopup data={blog}/>:
                        (
                          <div className="blog-list">
                            {
                              _.map(getData, (v, k) => {
                                return (
                                  <div className="blog-item" key={k}>
                                    <div className="blog-img-part">
                                      <div style={{
                                        backgroundImage: `url(${v.main_image_path})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: "no-repeat"
                                      }}/>
                                    </div>
                                    <div className="blog-title-part">
                                      <span className="blog-title">{v.title}</span>
                                      <span className="blog-sub-title"><div
                                        dangerouslySetInnerHTML={{__html: v.sub_title}}/></span>
                                      <span className="blog-desc"><div dangerouslySetInnerHTML={{__html: v.short_text}}/></span>
                                      <span className="blog-btn-read" onClick={() => {
                                        if (selected === v.id) {
                                          handleTabs("blog")
                                        } else {
                                          onSelectItem(v.id)
                                        }
                                      }}>read</span>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>)
                    }

                  </div>
                )
            }
          </li>
		</>

	)

}
