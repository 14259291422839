import {Config} from "../../config";
import Request from "../../http/http";

const buyTicket =  (type,id) =>async (dispatch)=>{
  try {

    const params= "&type="+type+"&id="+id;

    const response = await Request.get(Config.Ticket.buy+params);

    if(response.status){
      window.location.href=Config.Ticket.redirect+"?order="+response.data.data;
    }else if(response.data.status===3){
        dispatch({type:'POPUP_WINDOW',payload:true,modal: "login"})
    }else{
      alert(response.data.error)
    }
  }catch (e) {
    console.log(e.message)
  }
}
export default  {
  buyTicket
}

