import React from 'react';
import PasswordReset from "./views/pages/PasswordReset";
import CartScreen from "./views/pages/CartScreen";
import Payment from "./views/pages/Payment";

const light = React.lazy(() => import('./views/pages/Light'));
const dark = React.lazy(() => import('./views/pages/Dark'));
const user = React.lazy(() => import('./views/pages/User'));


const routes = [
  { path: '/light', exact:true, name: 'main', component: light },
  { path: '/light/:page',exact:true,  name: 'main', component: light },
  { path: '/light/:page/:id',exact:true,   name: 'main', component: light },
  { path: '/light/:page/:type/:id', exact:true,  name: 'main', component: light },
  { path: '/light/:page/:type/:room/:id',  name: 'main', component: light },
  { path: '/dark', exact:true,  name: 'main', component: dark },
  { path: '/dark/:page',exact:true,  name: 'main', component: dark },
  { path: '/dark/:page/:id',  name: 'main', component: dark },
  { path: '/user', exact:true, name: 'main', component: user },
  { path: '/user/:page',  name: 'User', component: user },
  { path: '/password/change/:token',  name: 'Password Reset', component: PasswordReset },
  { path: '/cart',  name: 'Cart', component: CartScreen },
  { path: '/pay/:status/:uuid',  name: 'Payment',exact:true, component: Payment },
  { path: '/pay/:status/:uuid/:platform',  name: 'Payment', component: Payment },
];

export default routes;



