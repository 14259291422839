import {AUTH, LOGOUT, MYTICKETS, PING, SET_USER_DATA} from "../actionTypes";

export const  User = (state={ isLogged:false , data:{},tickets:[]}, action)=>{
  switch (action.type){
    case AUTH:
      return {...state,isLogged: true, data:action.payload};
    case PING:
      return {...state,isLogged: action.payload};
    case SET_USER_DATA:
      return {...state,isLogged: true, data: action.payload};
    case LOGOUT:
      return {...state, isLogged: false,data:null}
    case MYTICKETS:
      return {...state, tickets: action.payload}
    default:
      return  state;
  }
}
