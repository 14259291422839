import Request from "../../http/http";
import {Config} from "../../config";
import {GET_SLOT_DATA} from "../actionTypes";
import _ from 'lodash'
const getSlots = () =>async (dispatch)=>{
  const response = await Request.get(Config.SlotData)
  dispatch({
    type: GET_SLOT_DATA,
    payload: _.groupBy(response.data,'catname')
  })
}

export default {
  getSlots
}
