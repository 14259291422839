let map = {};
export default function UseNavigation() {
  const url = new URL(window.location);



  const set=(key,value)=>{
    if(key && value){
      map[key]=value
      url.searchParams.set(key, value);
      window.history.replaceState({}, '', url);
    }
  }


  const remove=(key)=>{
    if(key ){
      delete map[key]
      url.searchParams.delete(key);
      window.history.replaceState({}, '', url);
    }
  }
  const get=(key)=>{
    if(key ){
      return url.searchParams.get(key);
    }
  }
  const getParams=()=>{
    return url.searchParams
  }
  return {set,remove,get,map,getParams}
}
