import React, {Component, useEffect, useState} from 'react';
import {SearchContainer, PopupWindow, LoginPopup, SearchPopup, SearchBtn, LoginBtn, Navigation} from "../../components";
import {Link, Redirect, useHistory, useParams} from "react-router-dom";
import "../../scss/style.scss";
import BLACK_LOGO from "../../assets/icons/black-logo.svg";
import {Actions} from "../../core";
import "../../scss/payment.scss"
window.checker=null;

const Payment=()=>{
  const history  = useHistory()
  const {status,uuid,platform} =  useParams()
  let [percent,setPercent]=useState(0)
  useEffect(()=>{
    if(status==="pending"){
      window.checker = setInterval(()=>{
            checkTransaction()
         },2000)
    }

    if(platform){
      window.document.querySelector(".side-bar").style.display="none"
      window.document.querySelector(".payment-status").style.width="100%"

    }

      console.log(status,uuid,platform)
  },[])

  const checkTransaction = ()=>{
    console.log("uuid",uuid)
    setPercent(++percent)
     Actions.User.checkTransaction(uuid).then((res)=>{
       switch (res.data.data){
         case "fail":
           if(window.checker){
             clearInterval(window.checker)
           }
           history.push("/pay/fail/unknown/"+(platform ||''))
           return;
         case "success":
           if(window.checker){
             history.push("/pay/success/"+uuid+"/"+(platform ||''))
             clearInterval(window.checker)
           }
           return;
         default:

           setPercent(++percent)
       }
     })
  }

  function renderStatus(status) {
      switch (status){
        case 'success': return "Success!";
        case 'fail': return "Payment Failed!";
        case 'pending': return "Pending";
      }
  }

  function renderContent(status) {
      switch (status){
        case 'success': return "Your request has been processed successfully";
        case 'fail': return "An error occurred while processing your payment";
        case 'pending': return "Your payment will be processed soon";
      }
  }

  return (

      <div className="page-wrapper light">
        <section className="side-bar">
          <div className="side-part">
            <Link id="logo" to={"/"} style={{backgroundImage: `url(${BLACK_LOGO})`}} />
            <LoginBtn/>
          </div>
          <div className="side-part">
            <a className="rotated-text" id="instagram" href={"https://www.instagram.com/basssiani/"} target={"_blank"}>INSTAGRAM</a>
            <a className="rotated-text" id="facebook"  href={"https://www.facebook.com/bassiani"} target={"_blank"}>FACEBOOK</a>
            <SearchBtn/>
            <Link id="ply" to={"/dark"}/>
          </div>
        </section>
        <section className="main-container payment-status">
            <div id="payment-title"> {renderStatus(status)} </div>
            <div id="payment-text">
              {renderContent(status)}
            </div>
          <div id="ticket-info">
            { status === "success" || status ==="pending" && "You'll receive a confirmation email shortly" }
            {
              status === "success" && !platform?
                <a id="ticket-link" href={`https://tickets.bassiani.com/member/ticket/${uuid}`} target={"_blank"}>VIEW TICKET</a>:
                status==="pending"?

                  <div className="custom-loader" data-text="Loading...">
                    <div style={{width:`${percent}%`}}/>
                  </div>
                  :
                  !platform && <Link id="ticket-link" to={"/light"}>MAIN PAGE</Link>
            }
          </div>
        </section>
        <SearchContainer/>
        <PopupWindow/>
        <LoginPopup/>
        <SearchPopup/>
      </div>

    )

}

export default Payment;

