import React,{Component} from 'react';
import {
  SearchContainer,
  PopupWindow,
  LoginPopup,
  SearchPopup,
  SearchBtn,
  LoginBtn,
  Navigation,
  Cart
} from "../../components";
import {Link,Redirect} from "react-router-dom";
import "../../scss/style.scss"
import BLACK_LOGO from "../../assets/icons/black-logo.svg";


class CartScreen extends Component{

  componentDidMount() {
  }


  render() {
    return (
      <div className="page-wrapper light">
        <section className="side-bar">
          <div className="side-part">
            <span id="logo"  style={{backgroundImage: `url(${BLACK_LOGO})`}} />
            <LoginBtn/>
          </div>
          <div className="side-part">
            <span className="rotated-text" id="instagram">INSTAGRAM</span>
            <span className="rotated-text" id="facebook">FACEBOOK</span>
            <SearchBtn/>
            <Link id="ply" to={"/dark"}/>
          </div>
        </section>
        <section className="main-container">
            <Cart/>
        </section>
        <SearchContainer/>
        <PopupWindow/>
        <LoginPopup/>
        <SearchPopup/>
      </div>
    )
  }
}

export default CartScreen;

