import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk';
import root from "./reducers";
import {logger} from "redux-logger";
//const middleware = applyMiddleware(thunkMiddleware, logger);
const middleware = applyMiddleware(thunkMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(root, composeEnhancers(middleware));



