import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Actions} from '../../core'

export const PasswordReset = ()=>{
  const reset = useSelector(state=>state.Store.PopupWindow.modals.passwordReset);
  console.log(reset)
  const dispatch = useDispatch();
  const [email,setEmail]=useState("")
  const [loader,setLoader]=useState(false)
  const [checkEmail,setCheckEmail]=useState(false)
  const resetParams = ()=>{
    setCheckEmail(false);
    setEmail("")

  }



  return reset && (
    <form  onSubmit={async (e) => {
      e.preventDefault();
    }}>
      <div className="log-in-sec" style={{height:checkEmail?"auto":'160px'}}>
        {
          checkEmail? <div className="login-box">
              <p className="log-un-title" style={{fontSize:'1.6rem',padding:'2rem'}}>Please check your email</p>
              <div className="submit-btn" >
                <input type="button"  value={"close"} onClick={()=>{
                  dispatch({type: 'POPUP_WINDOW', payload:false})
                  resetParams()
                }}/>
              </div>
            </div>:
            <>
              <div className="log-set" data-id="email" style={{display:'block'}}>
                <div className="email">
                  <div className="input-field">

                    <label htmlFor="set-email" className="mistake">Enter Email</label>
                    <input type="email" id="set-email" value={email} onChange={e=>setEmail(e.target.value)} placeholder="email"/>
                  </div>
                  <div className="submit-btn" onClick={()=>{
                    if(email.length>5){
                      setLoader(true)
                      Actions.User.ResetPassword(email).then(res=>{
                        setCheckEmail(res.status)

                        if(res.status){
                          setTimeout(()=>{
                            resetParams()
                          },3000)
                        }else{
                          alert("Please Check email")
                        }

                      }).finally(()=>setLoader(false))
                    }else{
                      alert("Please Check email")
                    }
                  }}><span>{loader?'Please wait...':"submit"}</span></div>
                </div>
              </div>
            </>
        }
      </div>
    </form>
  )
}
