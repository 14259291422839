import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash'
import {Actions} from "../../core";
import {Link} from "react-router-dom";

export const SearchPopup = (props)=>{

  const dispatch = useDispatch();
  const searchData = useSelector(state=>state.Store.Search.data);
	const popup  = useSelector(state=>state.Store.PopupWindow.modals.search)
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if(popup){
      setSearchText("")
      if(_.size(searchData)===0){
        dispatch(Actions.User.search())
      }
    }
  }, [popup]);


  const searchedData = useMemo(() => {
    if(searchText.trim().length===0){
        return []
    }else{
        return _.chain(searchData).filter(v=>v.searchText?.toLowerCase().includes(searchText.toLowerCase())).map(v=>{
          return {...v,searchText: v.searchText.toUpperCase().replace(searchText.toUpperCase(),`<span  style="color: #000000">${searchText.toUpperCase()}</span>`)};
        }).groupBy(v=>v.category).value()
    }
  }, [searchText]);


  return  (
		(popup) ?<div className="search-sec active">
			<input id="search-input" type="text" placeholder="Search" value={searchText} onChange={e=>setSearchText(e.target.value)}/>
      <hr/>
      <div>
        {
          _.map(searchedData, (data, category)=>{
              return <div key={category}>
                {
                  _.map(data,(v,k)=>{
                    return <Link to={v.url}   onClick={()=>dispatch({type: 'POPUP_WINDOW', payload:false})}>
                      {category} / <span key={k} className={"search-content"} dangerouslySetInnerHTML={{__html:v.searchText}}/>
                      </Link>
                  })
                }
              </div>
          })
        }
      </div>
		</div>:null
	)
}
