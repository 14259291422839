import React,{useState,useEffect} from "react";
import PropTypes from 'prop-types';
import { Events, Labels, Residents} from "..";
import {isNotEmpty} from "../../core/core/utils";

export const Navigation = (props)=>{
	const [active, setActive] = useState(props.params.page)
	const [id, setId] = useState(props.params.id);
	const [room] = useState(props.params.room);
	const [type,setType] = useState(props.params.type);
	useEffect(()=>{
    setActive(props.params.page)
    setId(props.params.id)
	},[props.params])

	const  checkActive = (props)=>{
		if(props===active){
			return 'active'
		}else if(active !=="" && active !==undefined && active !== props){
			return 'inactive';
		}else{
			return  '';
		}
	}
	const  handleTabs=(props)=>{
		if(props===active && isNotEmpty(id) ){
			if (props==='nights'){
				setId("")
				window.history.pushState({}, null, "/light/nights");
			}else if(props==='label'){
        setId("")
        window.history.pushState({}, null, "/light/label");
      }else if(props==='artists'){
        setId("")
        window.history.pushState({}, null, "/light/artists");
      }else{
				window.history.pushState({}, null, "/light");
			}
			setActive(props);

		}else if(props === active && (id==='' || id===undefined || id===-1)){
			setActive('')
			window.history.pushState({}, null, "/light");
		}else{
			window.history.pushState({}, null, "/light/"+props);
			setActive(props)
			setId('')
		}
	}
	return (
		<>
			<ul className="navigation">
				<li id="bassiani" className={`${checkActive('bassiani')}`}  >
					<div className="item-head" onClick={()=>handleTabs("bassiani")}><span>Bassiani</span></div>
					<ul className="item-body">
						<li className={"manifesto-page pointer"} onClick={()=>window.open("/manifest")}>Manifest</li>
            <li
              className={" pointer"}
              onClick={()=>{
              window.history.pushState({}, null, "/light/nights/horoom");
              setId("horoom");
              setActive("nights")
            }}>Horoom </li>
						<li className={" pointer"} onClick={()=>window.open("/communal")}>Communal</li>
{/*
						<li className={" pointer"} onClick={()=>window.open("/horoomnights")}>Horoom Nights</li>
*/}
						<li  className={" pointer"}  onClick={()=>window.open("/zenaari")}>Zenaari</li>
            <li className={" pointer"} onClick={()=>window.open("/eau-de-cologne/")}>Eau De Cologne</li>
            {/*<li>Spacehall</li>*/}
					</ul>
				</li>
				<Events  id={id} page={active} type={type}  room={room} checkActive={(e)=>checkActive(e)} handleTabs={(e)=>handleTabs(e)} onSelectEvent={(type,e)=>{
            setId(e)
            setActive("nights");
            window.history.pushState({}, null, "/light/nights/"+e);
        }} />
				<Labels  id={id} page={active} type={type} checkActive={(e)=>checkActive(e)} handleTabs={(e)=>handleTabs(e)} onSelectItem={e=>{
          if(id===e){
            window.history.pushState({}, null, "/light/label");
            setId("");
          }else{
            window.history.pushState({}, null, "/light/label/"+e);
            setId(e);
          }
          setActive("label")
        }} onSelectLabel={(page,id)=>{
          setId(id);
          setActive("label");
          setType(page);
          window.history.pushState({}, null, "/light/label/"+page+"/"+id);
        }}/>
				<Residents id={id} page={active} checkActive={(e)=>checkActive(e)} handleTabs={(e)=>handleTabs(e)} onSelectArtist={(type,e)=>{
          setId(e)
          setActive("artists");
          setType("artists");
          window.history.pushState({}, null, "/light/artists/"+e);
        }}/>
			</ul>
		</>
	)
}
Navigation.propTypes={
	params: PropTypes.object
}
Navigation.defaultProps={
	params:{
		page:'',
		id:''
	}
}
