import React from "react";
export const SearchContainer = () =>{
  return  (
    <section className="searched-container">
      <div className="searched-header">
        <span className="exit" id="close-searched"></span>
        <p className="title1">SEARCHED: DVS1</p>
        <p className="title2">5 RESULTS // 1 IN RESIDENTS, 1 IN BLOG, 3 IN EVENTS</p>
      </div>
      <div className="searched-content">
        <div className="searched-item">
          <section className="img-part">
            <div></div>
          </section>
          <section className="text-part">
            <p className="title1">SEARCHED: DVS1</p>
            <p className="title2">RESIDENTS</p>
            <p className="text">
              DVS1, იგივე ZAK KHUTORETSKY ამერიკაში მოღვაწე ტექნო სცენის უმნიშვნელოვანესი
              წარმომადგენელი, კლუბ Berghain / Panorama Bar-ის რეზიდენტი და ლეიბლის HUSH /
              Mistress Recordings-ის დამაარსებელი. მისი კარიერის დასაწყისი მშობლიური
              პეტერბურგიდან აშშ-ში გადასვლას უკავშირდება. ადრეული წლებიდან აქტიურად იყო
              ჩართული 90’ წლების რეივ-კულტურის განვითარებაში, 18 წლიდან აწყობდა ...
              DVS1, იგივე ZAK KHUTORETSKY ამერიკაში მოღვაწე ტექნო სცენის უმნიშვნელოვანესი
              წარმომადგენელი, კლუბ Berghain / Panorama Bar-ის რეზიდენტი და ლეიბლის HUSH /
              Mistress Recordings-ის დამაარსებელი. მისი კარიერის დასაწყისი მშობლიური
              პეტერბურგიდან აშშ-ში გადასვლას უკავშირდება. ადრეული წლებიდან აქტიურად იყო
              ჩართული 90’ წლების რეივ-კულტურის განვითარებაში, 18 წლიდან აწყობდა ...
            </p>
          </section>
        </div>
        <div className="searched-item">
          <section className="img-part">
            <div></div>
          </section>
          <section className="text-part">
            <p className="title1">SEARCHED: DVS1</p>
            <p className="title2">RESIDENTS</p>
            <p className="text">
              DVS1, იგივე ZAK KHUTORETSKY ამერიკაში მოღვაწე ტექნო სცენის უმნიშვნელოვანესი
              წარმომადგენელი, კლუბ Berghain / Panorama Bar-ის რეზიდენტი და ლეიბლის HUSH /
              Mistress Recordings-ის დამაარსებელი. მისი კარიერის დასაწყისი მშობლიური
              პეტერბურგიდან აშშ-ში გადასვლას უკავშირდება. ადრეული წლებიდან აქტიურად იყო
              ჩართული 90’ წლების რეივ-კულტურის განვითარებაში, 18 წლიდან აწყობდა ...
            </p>
          </section>
        </div>
        <div className="searched-item">
          <section className="img-part">
            <div></div>
          </section>
          <section className="text-part">
            <p className="title1">SEARCHED: DVS1</p>
            <p className="title2">RESIDENTS</p>
            <p className="text">
              DVS1, იგივე ZAK KHUTORETSKY ამერიკაში მოღვაწე ტექნო სცენის უმნიშვნელოვანესი
              წარმომადგენელი, კლუბ Berghain / Panorama Bar-ის რეზიდენტი და ლეიბლის HUSH /
              Mistress Recordings-ის დამაარსებელი. მისი კარიერის დასაწყისი მშობლიური
              პეტერბურგიდან აშშ-ში გადასვლას უკავშირდება. ადრეული წლებიდან აქტიურად იყო
              ჩართული 90’ წლების რეივ-კულტურის განვითარებაში, 18 წლიდან აწყობდა ...
            </p>
          </section>
        </div>
      </div>
    </section>
  )
}
