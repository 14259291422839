import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Actions} from '../../core'
import {PasswordReset} from "../index";
import {Link, useHistory} from "react-router-dom";

export const LoginPopup = ()=>{
  const history = useHistory()

  const ref = useRef();
  const dispatch = useDispatch();
	const login = useSelector(state=>state.Store.PopupWindow.modals.login);
  const reset = useSelector(state=>state.Store.PopupWindow.modals.passwordReset);
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [status, setStatus] = useState(null)

  if(reset){
    return <PasswordReset/>
  }


	return (
		login?
      <form  onSubmit={async (e) => {
        e.preventDefault();
        window.grecaptcha.execute('6Lc48aEdAAAAAPIKRiPrfZK-zFzlGxbx54HXgI21', {action: 'submit'}).then(async(token)=> {
          // Add your logic to submit to your backend server here.
          if(token){
            setStatus(await dispatch(Actions.User.auth({username: username, password: password,token:token})))
          }else{
            console.log(token)
          }
        });

      }}>
        <div className="log-in-sec">
          <div className="login-box">

            <p className="log-un-title">login</p>

              <label htmlFor="user-name-12" className={status===false?"mistake":""}>{(status===false)?"The username or password is incorrect":"username"}</label>
            <input type="text" id="user-name-12" ref={ref} placeholder="username" value={username} onChange={e=>setUsername(e.target.value)}/>
            <label htmlFor ="user-pasw">password</label>
            <input type="password" id="user-pasw" placeholder="password" value={password} onChange={e=>setPassword(e.target.value)}/>

            <div className="submit-btn" >
              <input type="submit"  value="submit" />
            </div>
            <div className="lig-in-opt" >
              {/*  <span>remember</span>*/}
              <span onClick={()=> dispatch({type:'POPUP_WINDOW',payload:true,modal: "passwordReset"})}> forgot password</span> </div>
            <div className="log-in-terms" >
              <span>by logging in, you agree to <strong onClick={()=>window.location.href="/dark/contact/terms"}>bassiani's terms and conditions</strong></span>
              <a href="/user/register"><span>not registered yet ?</span></a>
            </div>
          </div>
        </div>
      </form>

      :null
	)
}
