import { GET_SLOT_DATA} from "../actionTypes";

export const  Slot = (state={}, action)=>{
  switch (action.type){
    case GET_SLOT_DATA:
      return action.payload;
    default:
      return  state;
  }
}
