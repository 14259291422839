import {GET_LABEL, GET_LABELS} from "../actionTypes";

export const  Label = (state=[], action)=>{
	switch (action.type){
		case GET_LABELS:
			return action.payload;
		default:
			return  state;
	}
}

