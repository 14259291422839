import React, {useEffect, useRef, useState} from 'react';
import {Actions} from "../../core";
import {useDispatch,useSelector} from "react-redux";
import _ from 'lodash';
import {isNotEmpty} from "../../core/core/utils";

export const Store = ({id,handleTabs,checkActive,onSelectItem,page}) =>{
  const dispatch = useDispatch();
  const data = useSelector(state=>state.Store.Product);
  const [selected, setSelected] = useState(-1);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const [product,setProduct] = useState(null);
  const [showColor,setShowColor]=useState(false)
  const [showSizes,setShowSizes]=useState(false)
  const [colors,setColors]=useState([])
  const [sizes,setSizes] = useState([])
  const [details,setDetails] = useState([])
  const [selectedColor, setSelectedColor]=useState(null)
  const [selectedSize, setSelectedSize]=useState(null)
  const [colorHeight,setColorHeight]=useState(0)
  const [sizesHeight,setSizesheight]=useState(0)
  const [gallery, setGallery] = useState([]);

  const loadProduct=async (id) => {
    const response = await dispatch(Actions.Product.getProduct(id));
    if(response.status){
      const product = response.data.data;
      try {
        let media = response.data.data.images ;
        if(media && media.length  > 0){
          setGallery(media);
        }else{
          setGallery([...gallery,{ path: response['data']['data'][0]["main_image_path"] }])
        }
      }catch (e) {
        setGallery([...gallery,{ path: response['data']['data'][0]["main_image_path"] }])
      }

      setProduct(response.data.data)
      if(product.details && product.details.length > 0){
        setDetails(product.details);
        const colors = _.uniqBy(_.map(product.details, v=>{
          return {id:v.color_id,name: v.color_name};
        }),v=>v.id);
        setColorHeight(colors.length * 2.43 )
       setColors(colors)
      }
    }

  }
  useEffect(()=>{
    if(isNotEmpty(id) && page==='store' &&  id>0){
      loadProduct(id).finally();
    }else{
      setProduct(null)
    }
    setSelected(id)

  },[id])

  useEffect(()=>{
    if(page==="store" && _.size(data)===0){
      dispatch(Actions.Product.getProducts());

    }
  },[page])

  useEffect(()=>{

    if(details && details.length > 0){
      const sizes = _.uniqBy(_.map(_.filter(details,s=>s.color_id===selectedColor.id), size=>{
        return {id:size.size_id,name: size.size};
      }),v=>v.id);
      setSizes(sizes)
      setSizesheight(sizes.length * 2.43)
    }

  },[selectedColor])


  const renderColors=()=> {
      if(colors.length>0){
        return  (
          <ul  style={{height:`${showColor? colorHeight:0}rem`}}>
            {
                _.map(colors,(v,k)=>{
                  return  <li key={k} value={v.id} onClick={()=>{

                    setSelectedColor(v);
                  }}> {v.name}</li>
                })
            }
          </ul>
        )
      }
  }

  const renderSizes = () =>{
   return  <ul style={{height:`${showSizes? sizesHeight:0}rem`}}>
      {
          _.map(sizes, (s,k)=>{
            return  <li key={k} value={s.id} onClick={()=>setSelectedSize(s)}>{s.name}</li>
         })
      }
    </ul>
  }
  const renderCategories = () =>{
    return  (
      <ul id="category-list" className="category-list">
        {
          _.map(data, (v,k)=>{
            return <li key={k} onClick={()=>onSelectItem(v.id)}>
              <p>{v.category}</p>
              <p>
								<span >
                  {v.name}
								</span>
                <span>
									{v.secondName}
								</span>
              </p>
            </li>
          })
        }

      </ul>
    )
  }


  const renderItems=()=> {
    return <ul className="card-ul">
      {
        _.map(data, (v,k)=>{
          return <Item v={v} k={k} key={k} handleTabs={handleTabs} onSelectItem={onSelectItem} selected={selected}/>
        })
      }
    </ul>;
  }

    return (
    <>
      <li id="store"  className={`${checkActive('store')}`}  >
        <div className="item-head" onClick={()=>handleTabs("store")}><span>Store</span></div>
        <div className="item-body">
          <div id="category" className="category" onClick={()=>{
            if(selected === 'category'){
              handleTabs("store")
            }else{
              onSelectItem("category")
            }
          }}>
            category
          </div>


          {
            id && id!=="category"  && product? <div className="popup">
              <section className="left-side-sec">
                <div className="left-side-title">
                  {product.name} <br/>
                  {product.secondName}
                </div>
                <div className="store-item-det">
                  <div className="store-item-price">price : {_.round(product.price, 0)} gel</div>
                  <div id="store-item-color" className={`det-option ${showColor? 'active':''}`} onClick={()=>{
                    setShowColor(!showColor);
                  }}>
                    <p>
                      <span></span>
                      <span>{(selectedColor)? selectedColor.name:'color'}</span>
                    </p>
                      {
                        renderColors()
                      }

                  </div>
                  <div id="store-item-size" className={`det-option ${showSizes? 'active':''}`} onClick={()=>{
                    setShowSizes(!showSizes);
                  }} >
                    <p>
                      <span></span>
                      <span>{selectedSize? selectedSize.name: 'size'}</span>
                    </p>
                    {
                      renderSizes()
                    }
                  </div>
                </div>
                <div className="store-item-desc" dangerouslySetInnerHTML={{__html: product.short_desc}}/>
              </section>
              <section className="right-side-sec">
                <div className="right-side-title">
                  <p>
                    <span>Availability:</span>
                    <span>in stock</span>
                  </p>
                  <p id="store-order" >
                    <span onClick={()=>Actions.Ticket.buyTicket("store",product.id)}>ORDER</span>
                  </p>
                </div>
                <div className="right-side-img" style={{backgroundImage: `url(${gallery[galleryIndex]})`, backgroundSize:'cover'}}>
                  {
                    (galleryIndex<=0)? null:
                      <div className="gallery-arr arrow_prev" onClick={()=>setGalleryIndex(galleryIndex-1)}/>
                  }
                  {
                    (galleryIndex>=gallery.length-1)?null:
                      <div className="gallery-arr arrow_next" onClick={()=>setGalleryIndex(galleryIndex+1)}/>
                  }
                </div>
              </section>
            </div>:
              (id && id==="category") ?renderCategories(): renderItems()
          }

        </div>
      </li>
    </>
  )
}

const Item = ({v,k,handleTabs,onSelectItem ,selected}) =>{

  const ref = useRef(null);
  const titleRef= useRef(null)
  const [divWidth, setDivWidth]= useState(0)
  const [titleWidth, setTitleWidth]= useState(0)

  useEffect(()=>{
    if(ref.current){
      setDivWidth(ref.current.clientWidth);
    }
  },[ref])

  useEffect(()=>{
    if(titleRef.current){
      setTitleWidth(titleRef.current.clientWidth);
    }
  },[titleRef])
  return (<li className="card-li" key={k} onClick={()=>{
    if(selected===v.id.toString()){
      handleTabs("store")
    }else{
      onSelectItem(v.id)
    }
  }}>
    <div className="card-img" style={{backgroundImage: `url(${v.main_image_path})`,backgroundSize:'cover'}}/>
    <div className="card-title" ref={ref}>
      <span>{v.name}</span>
      <span ref={titleRef} className={`${divWidth<= titleWidth? 'long-text':""}`}>  {v.secondName} {divWidth<= titleWidth?v.secondName:""}</span>
      <span>{v.thirdName}</span>
    </div>
  </li>)
}
