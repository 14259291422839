import React, {useEffect, Suspense, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Actions} from '../../core';
import _ from  'lodash';
import {CHANGE_LANGUAGE, GET_EVENT} from "../../core/core/store/actionTypes";
import {isNotEmpty} from "../../core/core/utils";
import Moment from "moment";
import {ShareBtn} from "../index";
import UseNavigation from "../../core/core/utils/useNav";

export const EventPopup = (props)=>{
	const dispatch = useDispatch();
  const nav = UseNavigation();
  const lang = useSelector(state=>state.Lang);
  const  selectedEvent = useSelector(state=> state.Store.SelectedEvent)
  const [buyTicketDisable, setBuyTicketDisable] = useState(false);
  useEffect(()=>{
		if(props.page==='nights' && isNotEmpty(props.id)){
			dispatch(Actions.Event.getEvent(props.id))
		}
		return ()=>dispatch({type: GET_EVENT, payload:{}})
	},[props.id,dispatch])
	const renderLineUps=()=> {
		let lineUp = []
		if(selectedEvent.line_up.toString().length>0){
			try {
				 lineUp = JSON.parse(selectedEvent.line_up)
			}catch (e) {
				console.log(e)
			}
		}

		return  (
			(lineUp.length>0)?
				 _.map(lineUp, (value,index)=>{
					return (
						<div data-title={value.name} key={index}>
							{
							 value.data.length> 0?
								_.map(value.data, (v,k)=>
									<div key={k}>
										<p dangerouslySetInnerHTML={{__html:v.name}}/>
										<p>{v.hours}</p>
									</div>)
								: null
							}

						</div>
					)
				}):null
		)
	}
	const renderLoader =()=> {
		return  <div>loading...</div>
	}
	return  (
		<Suspense fallback={renderLoader()}>
			{
				!isNotEmpty(selectedEvent)?null:
					<div className="popup">
						<section>
              <div className="popup-lang" onClick={()=>dispatch({
                type:CHANGE_LANGUAGE,
                payload: lang==='ka'? 'en':'ka'
              })}>{lang==='ka'? 'EN':'KA'}</div>
							<div className="popup-title" >
								<p className={"date-title"}>{selectedEvent.title} {selectedEvent.event_start?Moment(selectedEvent.event_start).format("D MMMM // YYYY"):""} <span className="back"/></p>
                <p className={"artist-title"}>{selectedEvent.sub_title}</p>
							</div>

							<div className="popup-text">
								<span dangerouslySetInnerHTML={{__html: lang==='ka'?selectedEvent.text_ge:selectedEvent.text_en}}/>
							</div>
              <div className="popup-footer">
                <div className="footer-flexbox share-btn" style={{justifyContent:'flex-end'}}>
                  <div style={{minWidth:207}}>
                    <ShareBtn
                      host={window.location.origin}
                      description={selectedEvent.sub_title}
                      image={window.location.origin+selectedEvent?.share_image_path}
                      title={selectedEvent.title}
                      url={window.location.pathname}
                    />
                  </div>
                </div>
              </div>
							<div className="popup-date-box">
								<div className="event-date">
									{
										renderLineUps()
									}
								</div>
							</div>
              {
                selectedEvent.selling.toString()==="1"?(<div className="popup-footer">
                  <div className="footer-flexbox">
                    <span> {_.round(selectedEvent.price,2)} GEL</span>
                    {
                      nav.get("uuid")?

                        <a href={`https://payment.bassiani.com/payment?uuid=${nav.get("uuid")}&id=${props.id}`} target={"_blank"}><span >GET TICKET</span></a>  :
                        <span onClick={()=>{
                            if(!buyTicketDisable){
                              dispatch(Actions.Ticket.buyTicket("event",props.id))
                              setBuyTicketDisable(true)
                              setTimeout(()=>{
                                setBuyTicketDisable(false)
                              },60000)
                            }

                        }}>{buyTicketDisable? "PLEASE WAIT":"GET TICKET"}</span>
                    }

                  </div>
                </div>)
                :null
              }
            </section>
						<section><img src={selectedEvent.main_image_path} alt={""}/></section>
					</div>
			}
		</Suspense>
	)
}

EventPopup.propTypes={
	id: PropTypes.any
}
EventPopup.defaultProps={
	id: "-1"
}
