export const CHANGE_THEME="CHANGE_THEME";
export const GET_MAIN_MENU="GET_MAIN_MENU";
export const UPDATE_WALL="UPDATE_WALL";
export const AUTH="AUTH";
export const PING="PING";
export const GET_PODCAST="GET_PODCAST";
export const GET_SLOT_DATA="GET_SLOT_DATA";
export const GET_TEMPLATES="GET_TEMPLATES";
export const GET_ARTISTS="GET_ARTISTS";
export const GET_EVENTS ="GET_EVENTS";
export const GET_EVENT ="GET_EVENT";
export const GET_PODCASTS ="GET_PODCASTS";
export const GET_BLOGS ="GET_BLOGS";
export const GET_LABELS ="GET_LABELS";
export const GET_LABEL ="GET_LABELS";
export const CHANGE_LANGUAGE ="CHANGE_LANGUAGE";
export const GET_PRODUCTS ="GET_PRODUCTS";
export const SET_USER_DATA ="SET_USER_DATA";
export const LOGOUT ="LOGOUT";
export const MYTICKETS ="MYTICKETS";
export const GET_SEARCH ="GET_SEARCH";



