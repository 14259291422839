import Request from "../../http/http";
import {Config} from "../../config";
import {GET_PODCASTS} from "../actionTypes";

const getPodCasts = () =>async (dispatch)=>{
  console.log("get podcasts")
	const response = await Request.loader("podcast-loader").get(Config.PodCast.get);

  dispatch({
    type: GET_PODCASTS,
    payload: response.status? response.data.data.posts:[]
  })

}

const getPodCast = async (id) => await Request.get(Config.PodCast.getId+id)


export default {
	getPodCasts,
  getPodCast
}
