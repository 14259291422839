import { GET_TEMPLATES} from "../actionTypes";

export const  Template = (state=[], action)=>{
  switch (action.type){
    case GET_TEMPLATES:
      return action.payload;
    default:
      return  state;
  }
}
