import Request from "../../http/http";
import {Config} from "../../config";
import { GET_LABELS,GET_LABEL} from "../actionTypes";

const getLabels = (room="bassiani") =>async (dispatch)=>{
  const response = await Request.get(Config.Label.get+"&room="+room);
  console.log(response)
  dispatch({
    type: GET_LABELS,
    payload: {
      type: room,
      data:response.status? response.data.data.posts:[]
    }
  })

}
const getLabel = (id) =>async ()=>{
  return  await Request.get(Config.Label.getId+id);
}

export default {
  getLabels,
  getLabel
}
