import Request from "../../http/http";
import {Config} from "../../config";
import {GET_ARTISTS} from "../actionTypes";

const getArtists = () =>async (dispatch)=>{
	const response = await Request.loader("resident-loader").get(Config.Artists.get);
	dispatch({
		type: GET_ARTISTS,
		payload: response.status? response.data.data.posts:[]
	})
}
const getArtist = (id) =>async ()=>{
  return await Request.get(Config.Artists.getArtist+id);

}
export default {
  getArtist,
	getArtists
}
