import theme from "./theme";
import User from "./user";
import Slot from './slots'
import Template from './templates';
import PodCast from './podcast';
import Artist from './artists';
import Event from './events';
import Blog from './blogs';
import Label from './labels';
import Product from './products';
import Inviter from './inviter'
import Ticket from './ticket'
import Cart from './cart'
export default {
    theme,
    User,
    Slot,
    Template,
    PodCast,
    Artist,
    Event,
    Blog,
    Label,
   Product,
    Inviter,
   Ticket,
    Cart
}
