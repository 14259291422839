import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

export const ShareBtn = ({host,url,title,description,image,icon="Share"}) =>{
  const URI = "https://share.bassiani.com/?url="+host.concat(url)+"&title="+encodeURIComponent(title)+"&description="+encodeURIComponent(description)+"&image="+image;

  const [share, setShare] = useState(false);
  useEffect(() => {
    const contextMenu = document.querySelector(".share");
    contextMenu.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      setShare(!share)
      contextMenu.textContent = "copy share link";
    });
  }, []);



  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };


    return  <span className="share" onClick={()=>{
      if(share){
        copyToClipboard(URI)
      }else{
        window.open("https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(URI)+";src=sdkpreparse");
      }
    }}>{icon}</span>
}
ShareBtn.propTypes={
  host:PropTypes.string.isRequired,
  url:PropTypes.string.isRequired,
  title:PropTypes.string.isRequired,
  description:PropTypes.string.isRequired,
  image:PropTypes.string.isRequired,
}
