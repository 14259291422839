import Request from "../../http/http";
import {Config} from "../../config";
import {GET_EVENTS,GET_EVENT} from "../actionTypes";

const getEvents = (category=null) =>async (dispatch)=>{

  let params = (category? "&category="+category: "");
	const response = await Request.get(Config.Events.get+params);

	dispatch({
		type: GET_EVENTS,
		payload: response.status? response.data.data.posts:[]
	})

}

const getEvent = (id)=>async (dispatch)=>{

	try {
	  if(id==="horoom"){
	    dispatch(getEvents(id));
    }else{
      const response = await Request.get(Config.Events.event+id);
      dispatch({
        type: GET_EVENT,
        payload: response.status? response.data.data[0]:{}
      })
    }


	}catch (e) {
		console.log(e)
	}


}

const getGuest = (name)=>{
  return Request.get(Config.Inviter.getGuests.replace("{name}",name));
}

export default {
	getEvents,
	getEvent,
  getGuest
}
