import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Actions} from "../../core";
import {isNotEmpty} from "../../core/core/utils";
import {ShareBtn} from "../Share/ShareBtn";

export const PodcastPopup =({id}) =>{
  const [data,setData] = useState(null)
  useEffect(()=>{
    if(isNotEmpty(id)){
      load(id).finally()
    }
  },[id])
  const load=async (id) => {
    const response = await Actions.PodCast.getPodCast(id);
    setData(response.status? response.data.data[0]:null)
  }
  console.log(data)
  return  data?(
    <div className="li-body blog-item">
      <div className="blog-img-part">
        <div style={{backgroundImage: `url(${data.main_image_path})`,backgroundSize:'cover'}}/>
      </div>
      <div className="blog-title-part">
           <span className="blog-desc" dangerouslySetInnerHTML={{__html:data.text_en}}/>
           <hr/>
        <div style={{display:"flex",padding:"5px 10px",justifyContent:'right'}}>
          <ShareBtn    host={window.location.origin}
                       description={data.sub_title}
                       image={window.location.origin+data?.share_image_path}
                       title={data.row_title}
                       url={window.location.pathname}
          />
        </div>

      </div>
    </div>
  ):null
}

