import {GET_PODCASTS} from "../actionTypes";

export const  PodCast = (state=[], action)=>{
	switch (action.type){
		case GET_PODCASTS:
			return action.payload;
		default:
			return  state;
	}
}
