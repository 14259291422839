import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Actions} from '../../core'

export const CopyRight = ()=>{
  const copy = useSelector(state=>state.Store.PopupWindow.modals.copyright);
  return copy && (
    <div id="copy-right">

      <div>
        <p><span>CONCEPT, DESIGN</span><a href="mailto:tengiz.begoidze@gmail.com"><span>Tengiz Begoidze</span></a></p>
        <p><span>DESIGN</span><a href="mailto:giorgi4bregvadze@gmail.com"><span>Giorgi Bregvadze</span></a></p>
      </div>
      <div>
        <p><span>DEVELOPMENT</span><a href="mailto:bigvavateona@gmail.com"><span>Teona Bigvava</span></a></p>
        <p><span/><a href="mailto:kapana27@gmail.com"><span>Levan Kapanadze</span></a></p>
        <p><span/><a href="mailto:b.norakidze@gmail.com"><span>Bacho Norakidze</span></a></p>
      </div>
      <div>
        <span>© 2021 bassiani</span>
      </div>
    </div>
  )
}

