import React from  'react';
import {Navigation} from "./Navigation/Navigation";
import {Events} from './Events/Events'
import {EventPopup} from './EventPopup/EventPopup';
import {LoginPopup} from "./LoginPopup/LoginPopup";
import {PopupWindow} from "./PopupWindow";
import {SearchPopup} from "./SearchPopup/SearchPopup";
import {Residents} from "./Residents/Residents";
import {SearchContainer} from './SearchContainer/SearchContainer'
import {Podcasts} from './Podcasts/Podcasts'
import {Blogs} from './Blogs/Blogs';
import {Labels} from './Labels/Labels';
import {Store} from './Store/Store';
import {Contact} from './Contact/Contact';
import {ShareComponent} from './ShareComponent';
import {Cart} from "./Cart/Cart";
import {useDispatch, useSelector} from "react-redux";
import {ShareBtn} from "./Share/ShareBtn";
import {Link} from "react-router-dom";
import {PasswordReset} from './LoginPopup/PasswordReset'
import {CopyRight} from './LoginPopup/CopyRight'
export {
	Navigation,
	Events,
	EventPopup,
	LoginPopup,
	PopupWindow,
	SearchPopup,
	Residents,
  SearchContainer,
  Podcasts,
  Blogs,
  Labels,
  Store,
  Contact,
  LoginBtn,
  SearchBtn,
  ShareComponent,
  ShareBtn,
  Cart,
  PasswordReset,
  CopyRight
}

const LoginBtn = (props)=>{
  const dispatch = useDispatch();
  const user  = useSelector(state=>state.Store.User)

  return user.isLogged?
    <Link to={"/user/info"}><span className="rotated-text" >{user.data.fullname}</span></Link>
    :<span className="rotated-text" style={{cursor:"pointer"}} id="auth" onClick={()=>{
      dispatch({type:'POPUP_WINDOW',payload:true,modal: "login"})
    }}>login</span>
}
const SearchBtn = (props)=>{
  const dispatch = useDispatch();
  return <span id="rec" onClick={()=>{
    dispatch({type:'POPUP_WINDOW',payload:true,modal: "search"})
  }}/>
}
