import React, {Component, useEffect, useState} from 'react';
import {SearchContainer, PopupWindow, LoginPopup, SearchPopup, SearchBtn, LoginBtn, Navigation} from "../../components";
import {Link, Redirect, useHistory} from "react-router-dom";
import "../../scss/style.scss"
import {Actions} from "../../core";
import {useDispatch} from "react-redux";
import BLACK_LOGO from "../../assets/icons/black-logo.svg";


const PasswordReset=(props)=>{
  const {token}=props.match.params;
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [status,setStatus]=useState("")
  const dispatch = useDispatch()
  const history  =  useHistory()
  useEffect(() => {
    if(!token){
      history.replace("/light")
      return;
    }else{
      checkToken()
    }
  }, []);

  const  checkToken = async () => {
    const response = await Actions.User.CheckToken(token)
    if(!response.status){
      history.replace("/light");
      return;
    }

  }

  const submit=async () => {
    setStatus("")
    if (password.length > 5 && password === repeatPassword) {
      const response = await Actions.User.UpdatePassword(password)
      if(response.status){
        setStatus("Password changed successfully")
        setTimeout(()=>{
          history.push("/light")
          dispatch({type:'POPUP_WINDOW',payload:true,modal: "login"})
        },1000)
      }  else{
        setStatus("An error was reported")
      }
    } else {
      alert("Please check fields")
    }
  }

  return (

      <div className="page-wrapper light">
        <section className="side-bar">
          <div className="side-part">
            <Link id="logo" to={"/"} style={{backgroundImage: `url(${BLACK_LOGO})`}} />
            <LoginBtn/>
          </div>
          <div className="side-part">
            <a className="rotated-text" id="instagram" href={"https://www.instagram.com/basssiani/"} target={"_blank"}>INSTAGRAM</a>
            <a className="rotated-text" id="facebook"  href={"https://www.facebook.com/bassiani"} target={"_blank"}>FACEBOOK</a>
            <SearchBtn/>
            <Link id="ply" to={"/dark"}/>
          </div>
        </section>
        <section className="main-container">
          <div className="log-set" data-id="password">
            <div className="password">
              <div className="input-field">
                <label htmlFor="set-pass1">Enter New Password</label>
                <input type="password" id="set-pass1" placeholder="password" value={password} onChange={e=>setPassword(e.target.value)}/>
              </div>
              <div className="input-field">
                <label htmlFor="set-pass2">Confirm password</label>
                <input type="password" id="set-pass2" placeholder="password" value={repeatPassword} onChange={e=>setRepeatPassword(e.target.value)}/>
              </div>
              {
                status.length>0 && <span>{status}</span>
              }
              <div className="submit-btn" onClick={()=>submit()}><span>submit</span></div>
            </div>
          </div>
        </section>
        <SearchContainer/>
        <PopupWindow/>
        <LoginPopup/>
        <SearchPopup/>
      </div>

    )

}

export default PasswordReset;

