import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CHANGE_LANGUAGE} from "../../core/core/store/actionTypes";
import {ShareBtn} from "../Share/ShareBtn";

export const BlogPopup = ({data}) =>{
  const dispatch = useDispatch();
  const lang = useSelector(state=>state.Lang);

  return  (
    <div className="popup" >
      <section>
        <div className="blog-item">
          <div className="blog-img-part">
            <div style={{backgroundImage: `url(${data.main_image_path})`, backgroundSize: 'cover'}}/>
          </div>
          <div className="blog-title-part"><span className="blog-title">{data.title}</span> <span
            className="blog-sub-title">{data.sub_title}</span>
            <span className="blog-desc" dangerouslySetInnerHTML={{__html: data.short_text}}/>
          </div>
        </div>
        {/*<div className="popup-lang" onClick={()=>dispatch({
          type:CHANGE_LANGUAGE,
          payload: lang==='ka'? 'en':'ka'
        })}>{lang==='ka'? 'EN':'KA'}</div>*/}
        <div className="popup-text">		<span dangerouslySetInnerHTML={{__html: lang==="ka"?data.text_ge:data.text_en}} style={{
          fontSize:"18px"
        }}/>
          <div className={"share_btn"}>
            <ShareBtn
              host={window.location.origin}
              description={data.sub_title}
              image={window.location.origin+data?.share_image_path}
              title={data.title}
              url={window.location.pathname}
            />
          </div>

        </div>
      </section>
    </div>
  )
}
