import _ from 'lodash';
const INITIAL_STATE ={
	status: false,
	modals:{
		login:false,
		search:false,
		inviter:false,
    passwordReset:false,
    copyright:false
	}

}


export const PopupWindow = (state = INITIAL_STATE, action) => {
	let modals;
	if(!action.payload){
		modals = _.fromPairs(_.map(state.modals, (value,key)=>{
			return  [key,false]
		}))
	 }else{
		modals = _.fromPairs(_.map(state.modals, (value,key)=>{
			return  [key,(key===action.modal)]
		}));
	}

	switch (action.type) {
		case 'POPUP_WINDOW':
			return {...state,status: action.payload, modals: modals };
		default:
			return state
	}
}
