import React, {useState} from "react";
import {Actions} from "../../core";
import  _ from 'lodash';

export const LostAndFound = ()=>{

  const [btnText, setBtnText] = useState("submit");
  const fields = ["fullName","email","phone","dateOfLost","description"];
  const [lostData, setLostData] = useState({
      fullName:'',
      email:"",
      phone:"",
      dateOfLost:"",
      description:""
  });

  const checker = () =>{
      return _.map(fields,f=>{
        if(lostData[f].length===0){
          return 1;
        }
        return 0;
      }).filter(v=>v===1).length===0
  }


  const onSubmit=()=> {
    if(checker()){
      setBtnText("Please wait ...")
       Actions.User.LostAndFound(lostData).then(response=>{
        console.log(response)
         if(response?.data?.status==="OK"){
           setBtnText("Sent successfully")
         }else{
           setBtnText("An error was reported")
         }
       }).catch(e=>{
         setBtnText("An error was reported")
       }).finally(()=>{
         setTimeout(()=>{
           setBtnText("Submit")
         },2000)
       });
    }else{
      alert("Please check all fields")
    }

  }

  return (
    <div className="lost-form">
      <div className="lost-input-field">
        <label htmlFor="lost-fullname">full name</label>
        <input type="text" id="lost-fullname" value={lostData.fullName} onChange={e=>setLostData({...lostData,fullName:e.target.value})}/>
      </div>
      <div className="lost-input-field">
        <label htmlFor="lost-email">email</label>
        <input type="email" id="lost-email" value={lostData.email} onChange={e=>setLostData({...lostData,email:e.target.value})}/>
      </div>
      <div className="lost-input-field">
        <label htmlFor="lost-phone">phone</label>
        <input type="number" id="lost-phone" value={lostData.phone} onChange={e=>setLostData({...lostData,phone:e.target.value})}/>
      </div>
      <div className="lost-input-field">
        <label htmlFor="lost-date" >date of loss</label>
        <input type="date" id="lost-date" value={lostData.dateOfLost} onChange={e=>setLostData({...lostData,dateOfLost:e.target.value})}/>
      </div>
      <div className="lost-input-field textarea-field">
        <label htmlFor="lost-text">Description of lost item(S)</label>
        <textarea id="lost-text" value={lostData.description} onChange={e=>setLostData({...lostData,description:e.target.value})}/>
      </div>
      <div className="lost-input-field lost-submit-field">
        <div id="lost-submit" onClick={()=>onSubmit()}>{btnText}</div>
      </div>
    </div>
  )
}
