import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import './Cart.css'
import {Actions} from "../../core";
import {useDispatch} from "react-redux";

export const Cart = () =>{
  const dispatch =  useDispatch();
  const [details,setDetails]=useState({
    email:"kapana27@gmail.com",
    firstName:"Levan",
    lastName:"Kapanadze",
    company:"kompania",
    address:"misamarti",
    postalCode:"12312",
    city:"21321",
    country:"123123",
    phone:"599055492",
  });
  const [data,setData]=useState(localStorage.getItem("cart")? JSON.parse(localStorage.getItem("cart")):[
    {
      id:25,
      item:"თსლ შავი ზედა",
      quantity:1,
      price:120
    },
    {
      id:24,
      item:"თსლ შავი ზედა",
      quantity:1,
      price:60
    },
    {
      id:23,
      item:"თსლ შავი ზედა",
      quantity:1,
      price:40
    }
  ])

  useEffect(()=>{
      localStorage.setItem("cart",JSON.stringify(data))
  },[data])


  const checkout=()=> {
    let cart={
      "shippingAddress":details,
      "cartDetails":data
    }
    return dispatch(Actions.Cart.checkout(cart));
  }

  return  (
     <div align={"right"}>
        <table>
            <thead>
                <tr>
                  <th>#</th>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Sum</th>
                </tr>
            </thead>
            <tbody>
            {
              _.map(data,(v,i)=>{
                 return  (
                   <tr>
                     <td>{i+1}</td>
                     <td>{ v.item }</td>
                     <td><input type="number" value={v.quantity} onChange={event => {
                       data[i]["quantity"]=event.target.value;
                       setData([...data])
                     }}/></td>
                     <td>{v.price}</td>
                     <td>{ parseFloat(v.price * v.quantity).toFixed(2)}</td>
                   </tr>
                 )
              })
            }
            </tbody>
        </table>
       <button onClick={()=>checkout()} >checkout</button>

     </div>
  );
}

