import Request from "../../http/http";
import {Config} from "../../config";
import {GET_TEMPLATES} from "../actionTypes";
const getTemplates = () =>async (dispatch)=>{
  const response = await Request.get(Config.Templates)
  console.log(response)
  dispatch({
    type: GET_TEMPLATES,
    payload: response.data
  })
}

export default {
  getTemplates
}
