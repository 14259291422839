import React, {Component, Suspense} from 'react';
import {connect} from "react-redux";
import {Actions} from  './core/'
import {bindActionCreators} from "redux";
import  {
    Switch,Route,Redirect,BrowserRouter
} from "react-router-dom";
import routes from "./routes";
import {LoginPopup, PopupWindow, SearchPopup} from "./components";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
)

class App extends Component {
componentDidMount() {
  this.props.ping()
}
  render() {
    return (
        <Suspense fallback={loading}>
            <BrowserRouter>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                        <route.component {...props} />
                                )} />
                        )
                    })}
                  <Redirect from="/" to="/light" />

                </Switch>
              <SearchPopup/>
            </BrowserRouter>
            <LoginPopup/>

            <PopupWindow/>
        </Suspense>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      ping: Actions.User.ping
  }, dispatch);

}
export default connect(null,mapDispatchToProps)(App);
