import _ from 'lodash';
import {TimeOut} from "./TimeOut";
export default {
  TimeOut
}
export const isNotEmpty = (value) =>{
  if(typeof value ==="number"){
      return  value>0;
  }
  return  (!_.isUndefined(value) && !_.isEmpty(value) && value !=="")
}
